import validate from "/Users/alex_bozh/VsCode/nuxt/smaildell/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/Users/alex_bozh/VsCode/nuxt/smaildell/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-only": () => import("/Users/alex_bozh/VsCode/nuxt/smaildell/middleware/admin-only.ts"),
  "guest-only": () => import("/Users/alex_bozh/VsCode/nuxt/smaildell/middleware/guest-only.ts"),
  "user-only": () => import("/Users/alex_bozh/VsCode/nuxt/smaildell/middleware/user-only.ts")
}