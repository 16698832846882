import { default as adminTVRnBdQCw2Meta } from "/Users/alex_bozh/VsCode/nuxt/smaildell/pages/admin.vue?macro=true";
import { default as indexrKBSmXCpWYMeta } from "/Users/alex_bozh/VsCode/nuxt/smaildell/pages/catalog/[category]/[product]/index.vue?macro=true";
import { default as index3Cv2hBezxGMeta } from "/Users/alex_bozh/VsCode/nuxt/smaildell/pages/catalog/[category]/index.vue?macro=true";
import { default as indexmi6lbVNvMTMeta } from "/Users/alex_bozh/VsCode/nuxt/smaildell/pages/catalog/index.vue?macro=true";
import { default as contactv8NMaoQVNPMeta } from "/Users/alex_bozh/VsCode/nuxt/smaildell/pages/contact.vue?macro=true";
import { default as indexXxB9S8A1htMeta } from "/Users/alex_bozh/VsCode/nuxt/smaildell/pages/index.vue?macro=true";
import { default as loginEYAb2pgMwpMeta } from "/Users/alex_bozh/VsCode/nuxt/smaildell/pages/login.vue?macro=true";
import { default as pricesEB9znlfL3HMeta } from "/Users/alex_bozh/VsCode/nuxt/smaildell/pages/prices.vue?macro=true";
import { default as privatecbGyACcVXXMeta } from "/Users/alex_bozh/VsCode/nuxt/smaildell/pages/private.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: adminTVRnBdQCw2Meta || {},
    component: () => import("/Users/alex_bozh/VsCode/nuxt/smaildell/pages/admin.vue")
  },
  {
    name: "catalog-category-product",
    path: "/catalog/:category()/:product()",
    component: () => import("/Users/alex_bozh/VsCode/nuxt/smaildell/pages/catalog/[category]/[product]/index.vue")
  },
  {
    name: "catalog-category",
    path: "/catalog/:category()",
    component: () => import("/Users/alex_bozh/VsCode/nuxt/smaildell/pages/catalog/[category]/index.vue")
  },
  {
    name: "catalog",
    path: "/catalog",
    component: () => import("/Users/alex_bozh/VsCode/nuxt/smaildell/pages/catalog/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/Users/alex_bozh/VsCode/nuxt/smaildell/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/alex_bozh/VsCode/nuxt/smaildell/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginEYAb2pgMwpMeta || {},
    component: () => import("/Users/alex_bozh/VsCode/nuxt/smaildell/pages/login.vue")
  },
  {
    name: "prices",
    path: "/prices",
    component: () => import("/Users/alex_bozh/VsCode/nuxt/smaildell/pages/prices.vue")
  },
  {
    name: "private",
    path: "/private",
    meta: privatecbGyACcVXXMeta || {},
    component: () => import("/Users/alex_bozh/VsCode/nuxt/smaildell/pages/private.vue")
  }
]